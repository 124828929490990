<template>
  <div class="menuManage">
    <ds-header title="菜单管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="管理系统：">
        <a-select
          v-model="searchForm.manageSystem"
          style="width: 200px"
          placeholder="请选择平台"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in options" :key="option.platformId" :value="option.platformId">
            {{ option.platformName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <SearchResetModule @search="search" @resetSearchForm="resetSearchForm"></SearchResetModule>
      </a-form-item>
    </a-form>
    <div style="padding-top: 15px" v-if="$checkButtonPermissions($buttonPermissionsConfig.system.menuManage.add)">
      <ds-button :type="$DSBT.DS_ADD" title="新增菜单" @click="addMenu" />
    </div>
    <div style="margin-top: 10px">
      <ds-table
        :scroll="{ x: 1500 }"
        :rowKey="(record, index) => record.menuId"
        :columns="table.columns"
        :data-source="table.data"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <ActionModule :actions="actions" @action="action" :data="text"></ActionModule>
        </span>
      </ds-table>
    </div>
    <AddMenu :isShowAdd.sync="isShowAdd" :isEdit="isEdit" @uploadData="uploadData" :options="options"></AddMenu>
    <AddMenu :isShowEdit.sync="isShowEdit" :isEdit="isEdit" @uploadData="uploadData" :menuInfo="menuInfo" :options="options"></AddMenu>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import AddMenu from "./components/addMenu";
import * as api from "@/api/system/menuManage";
import { getPlatformList } from "@/api/common";
import { REQUEST_CODE } from "@/constant/status";
import { mapState } from "vuex";
import PagingConfig from "@/utils/pagingConfig";
import MenuType from "./utils/menuTypeUtil";
import ActionConfig from "@/utils/actionConfig";
import ActionModule from "@/components/actionModule";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import SearchResetModule from "@/components/searchResetModule";

export default {
  name: "MenuManage",
  components: {
    AddMenu,
    ActionModule,
    ConfirmBoxModule,
    SearchResetModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      //是否显示添加
      isShowAdd: false,
      //是否显示编辑
      isShowEdit: false,
      //是否编辑
      isEdit: false,
      searchForm: {
        //管理系统
        manageSystem: undefined,
      },
      //管理系统选项
      options: [],
      table: {
        columns: [
          {
            title: "系统",
            dataIndex: "platformName",
            key: "platformName",
          },
          {
            title: "名称",
            dataIndex: "menuName",
            key: "menuName",
          },
          {
            title: "类型",
            dataIndex: "menuType",
            key: "menuType",
            customRender: (text) => {
              if (text === MenuType.MENU_TYPE_CATALOGUE) {
                return "目录";
              } else if (text === MenuType.MENU_TYPE_MENU) {
                return "菜单";
              } else {
                return "按钮";
              }
            },
          },
          {
            title: "排序",
            dataIndex: "orderNum",
            key: "orderNum",
          },
          {
            title: "路由",
            dataIndex: "menuPath",
            key: "menuPath",
          },
          {
            title: "授权标识",
            dataIndex: "authority",
            key: "authority",
          },
        ],
        data: [],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: PagingConfig.defaultPage,
          pageSize: PagingConfig.defaultPageSize,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      //菜单信息
      menuInfo: {},
      //操作集合
      actions: [
        {
          type: ActionConfig.edit.ACTION_TYPE,
          title: ActionConfig.edit.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.system.menuManage.edit
        },
        {
          type: ActionConfig.delete.ACTION_TYPE,
          title: ActionConfig.delete.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.system.menuManage.delete
        },
      ],
    };
  },
  methods: {
    //查询
    search() {
      this.refreshData();
    },
    //重置
    resetSearchForm() {
      if (this.options && this.options.length) {
        this.searchForm = {
          manageSystem: this.options[0].platformId,
        };
      } else {
        this.searchForm = {
          manageSystem: undefined,
        };
      }
      this.refreshData();
    },
    //添加菜单
    addMenu() {
      this.isEdit = false;
      this.isShowAdd = true;
    },
    //编辑
    edit(text) {
      this.isEdit = true;
      this.menuInfo = text;
      this.isShowEdit = true;
    },
    //删除
    async remove(text) {
      const confirmData = {
        title: "是否确认删除该菜单",
        content: "确定删除该菜单",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.deleteMenu(text.menuId);
    },
    //列表操作
    action(actionType, data) {
      if (actionType === ActionConfig.edit.ACTION_TYPE) {
        this.edit(data);
      } else if (actionType === ActionConfig.delete.ACTION_TYPE) {
        this.remove(data);
      }
    },
    //刷新数据
    uploadData() {
      this.refreshData();
    },
    //分页操作
    pageChange(option) {
      this.refreshData(option.current, option.pageSize);
    },

    /**网络请求 */
    //获取菜单列表
    async getMenuList() {
      try {
        const result = await api.getMenuList(
          this.searchForm.manageSystem,
          this.table.pagination.current,
          this.table.pagination.pageSize
        );
        if (result.code === "200") {
          this.table.data = result.data.menuList;
          this.table.pagination.total = result.data.count;
        }
      } catch (error) {}
    },
    //删除菜单
    async deleteMenu(id) {
      try {
        const result = await api.deleteMenu(id);
        if (result.code === "200") {
          this.$message.success("删除成功");
          this.refreshData();
        } else {
          this.$message.success(result.msg);
        }
      } catch (error) {
        this.$message.success("删除失败");
      }
    },
    //获取平台列表
    async getPlatform() {
      try {
        const result = await getPlatformList();
        if (result.code === REQUEST_CODE) {
          this.options = result.data;
          if (this.options && this.options.length) {
            this.searchForm.manageSystem = this.options[0].platformId;
            this.refreshData();
          }
        }
      } catch (error) {}
    },

    //刷新数据
    refreshData(current = PagingConfig.defaultPage, pageSize = PagingConfig.defaultPageSize) {
      if (pageSize !== this.table.pagination.pageSize) {
        this.table.pagination.current = PagingConfig.defaultPage;
      } else {
        this.table.pagination.current = current;
      }
      this.table.pagination.pageSize = pageSize;
      this.getMenuList();
    },
  },
  created() {
    this.getPlatform();
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction());
  },
};
</script>

<style lang="scss" scoped>
</style>
