
// 菜单类型枚举
export const MenuTypeEnum = {
  MENU_TYPE_CATALOGUE: 'M', // 目录
  MENU_TYPE_MENU: "C", // 菜单
  MENU_TYPE_BUTTON: "F" // 按钮
}
// 菜单类型枚举
export const MenuTypeNameEnum = {
  MENU_TYPE_CATALOGUE: '目录',
  MENU_TYPE_MENU: '菜单',
  MENU_TYPE_BUTTON: '按钮'
}
// 路由名称枚举
export const PathNameEnmu = {
  Router: '路由',
  Link: '链接'
}
// 菜单类型选项
export const menuTypeOptions = [
  {
    value: MenuTypeEnum.MENU_TYPE_CATALOGUE,
    label: MenuTypeNameEnum.MENU_TYPE_CATALOGUE
  },
  {
    value: MenuTypeEnum.MENU_TYPE_MENU,
    label: MenuTypeNameEnum.MENU_TYPE_MENU
  },
  {
    value: MenuTypeEnum.MENU_TYPE_BUTTON,
    label: MenuTypeNameEnum.MENU_TYPE_BUTTON
  }
]

export const test = (ob) => {
  return 1;
}

/**
 * 菜单类型信息配置
 * @menuNamelabel 菜单名称label
 * @menuNamePlaceholder 菜单名称占位符
 * @superMenu 上级菜单
 * 
 */
export const menuTypeInfoConfig = {
  M: {
    menuNamelabel: `${MenuTypeNameEnum.MENU_TYPE_CATALOGUE}名称`,
    menuNamePlaceholder: `请输入${MenuTypeNameEnum.MENU_TYPE_CATALOGUE}名称`,
    superMenu: `上级${MenuTypeNameEnum.MENU_TYPE_CATALOGUE}`,
    sortPlaceholder: `请输入${MenuTypeNameEnum.MENU_TYPE_CATALOGUE}排序`,
    pathNameLabel: {
      0: PathNameEnmu.Router,
      1: PathNameEnmu.Router
    },
    pathPlaceholder: {
      0: `请输入${MenuTypeNameEnum.MENU_TYPE_CATALOGUE}路由`,
      1: `请输入${MenuTypeNameEnum.MENU_TYPE_CATALOGUE}路由`
    }
  },
  C: {
    menuNamelabel: `${MenuTypeNameEnum.MENU_TYPE_MENU}名称`,
    menuNamePlaceholder: `请输入${MenuTypeNameEnum.MENU_TYPE_MENU}名称`,
    superMenu: `上级${MenuTypeNameEnum.MENU_TYPE_CATALOGUE}`,
    sortPlaceholder: `请输入${MenuTypeNameEnum.MENU_TYPE_MENU}排序`,
    pathNameLabel: {
      0: PathNameEnmu.Router,
      1: PathNameEnmu.Link
    },
    pathPlaceholder: {
      0: `请输入${MenuTypeNameEnum.MENU_TYPE_MENU}路由`,
      1: `请输入链接地址`
    }
  },
  F: {
    menuNamelabel: `${MenuTypeNameEnum.MENU_TYPE_BUTTON}名称`,
    menuNamePlaceholder: `请输入${MenuTypeNameEnum.MENU_TYPE_BUTTON}名称`,
    superMenu: `所属${MenuTypeNameEnum.MENU_TYPE_MENU}`,
    sortPlaceholder: `请输入${MenuTypeNameEnum.MENU_TYPE_BUTTON}排序`,
    pathNameLabel: {
      0: PathNameEnmu.Router,
      1: PathNameEnmu.Router
    },
    pathPlaceholder: {
      0: `请输入${MenuTypeNameEnum.MENU_TYPE_BUTTON}路由`,
      1: `请输入${MenuTypeNameEnum.MENU_TYPE_BUTTON}路由`
    }
  }
}
export default {
  MENU_TYPE_CATALOGUE: "M",
  MENU_TYPE_MENU: "C",
  MENU_TYPE_BUTTON: "F"
}


