<template>
  <div class="addMenu">
    <a-modal v-model="show" :title="showTitle" :footer="null" :maskClosable="false">
      <div>
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="系统：" prop="platformId">
            <a-select
              v-model="form.platformId"
              placeholder="请选择系统"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              @change="selectPlatform"
            >
              <a-select-option v-for="option in options" :key="option.platformId" :value="option.platformId">
                {{ option.platformName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="类型：" prop="menuType">
            <a-radio-group v-model="form.menuType">
              <a-radio :value="item.value" v-for="(item, index) in menuTypeOptions" :key="index"> {{item.label}} </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="外部链接：" prop="isLink" v-if="form.menuType === menuType.MENU_TYPE_MENU">
            <a-radio-group v-model="form.isLink">
              <a-radio :value="1"> 是 </a-radio>
              <a-radio :value="0"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item :label="menuConfigInfo.menuNamelabel" prop="menuName">
            <a-input :placeholder="menuConfigInfo.menuNamePlaceholder" v-model="form.menuName" />
          </a-form-model-item>
          <a-form-model-item :label="menuConfigInfo.superMenu">
            <a-tree-select
              v-model="form.parentMenuId"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="menuList"
              placeholder="请选择"
              :replaceFields="{
                children: 'children',
                title: 'menuName',
                key: 'menuName',
                value: 'menuId',
              }"
            >
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="排序：" prop="orderNum">
            <a-input :placeholder="menuConfigInfo.sortPlaceholder" v-model="form.orderNum" />
          </a-form-model-item>
          <a-form-model-item :label="menuConfigInfo.pathNameLabel[form.isLink]" prop="menuPath">
            <a-input :placeholder="menuConfigInfo.pathPlaceholder[form.isLink]" v-model="form.menuPath" />
          </a-form-model-item>
          <a-form-model-item label="组件：" prop="component" v-if="directoryTypeNotDisplayed && linkNotDisplayed">
            <a-input placeholder="请输入组件" v-model="form.component" />
          </a-form-model-item>
          <a-form-model-item label="授权标识：" prop="authority">
            <a-input placeholder="请输入授权标识" v-model="form.authority" />
          </a-form-model-item>
          <!-- <a-form-model-item label="链接地址：" v-show="form.menuType == 'C'" prop="">
            <a-input placeholder="请输入链接地址" v-model="form.linkPath" />
          </a-form-model-item> -->
          <a-form-model-item label="是否展示：" prop="visible">
            <a-select
              v-model="form.visible"
              placeholder="请选择"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option v-for="option in showOption" :key="option.id" :value="option.id">
                {{ option.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="text-align: center">
        <ds-button :type="$DSBT.DS_CANCEL" @click="cancel" />
        <ds-button :type="$DSBT.DS_SAVE" @click="save" style="margin-left: 20px" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as api from '@/api/system/menuManage';
import debounce from 'lodash/debounce';
import MenuType from '../../utils/menuTypeUtil';
import { menuTypeOptions, menuTypeInfoConfig } from '../../utils/menuTypeUtil'

const initForm = {
  //系统
  platformId: undefined,
  //系统名称
  platformName: '',
  //默认选中菜单
  menuType: 'M',
  //菜单名称
  menuName: '',
  //上级菜单
  parentMenuId: undefined,
  //菜单编号
  orderNum: '',
  //菜单路由
  menuPath: '',
  //组件
  component: '',
  //权限标识
  authority: '',
  //是否显示
  visible: undefined,
  //组件名称
  moduleName: '',
  // 是否是外部链接
  isLink: 0,
  //链接地址
  linkPath: '',
};

export default {
  name: 'AddMenu',
  props: {
    // 是否是新增显示
    isShowAdd: {
      type: Boolean,
      default: false,
    },
    // 是否是编辑显示
    isShowEdit: {
      type: Boolean,
      default: false,
    },
    // 菜单信息
    menuInfo: {
      type: Object,
      default: null,
    },
    // 平台列表
    options: {
      type: Array,
      default: [],
    },
  },
  watch: {
    // 监听传入菜单数据进行数据初始化
    menuInfo(newVal) {
      if (this.isShowEdit) {
        this.form = newVal;
        this.form.isLink = newVal.isExternalLink ? 1 : 0
        if (this.form.parentMenuId === '0') {
          this.form.parentMenuId = undefined;
        }
        this.form.visible = this.form.visible === false ? '0' : '1';
        this.getMenuList();
      }
    },
  },
  computed: {
    // dialog是否显示
    show: {
      set() {
        if (this.isShowEdit) {
          this.$emit('update:isShowEdit', false);
        } else {
          this.$emit('update:isShowAdd', false);
          this.resetData();
        }
      },
      get() {
        if (this.isShowEdit) {
          return this.isShowEdit;
        } else {
          return this.isShowAdd;
        }
      },
    },
    // 菜单配置信息
    menuConfigInfo() {
      return menuTypeInfoConfig[this.form.menuType];
    },
    // 菜单label名称
    // menuLabName: {
    //   get() {
    //     return menuNameConfig[this.form.menuType];
    //     // if (this.form.menuType === this.menuType.MENU_TYPE_CATALOGUE) {
    //     //   return '目录名称：';
    //     // } else if (this.form.menuType === this.menuType.MENU_TYPE_MENU) {
    //     //   return '菜单名称：';
    //     // } else {
    //     //   return '按钮名称：';
    //     // }
    //   },
    // },
    // 显示title文案
    showTitle: {
      get() {
        if (this.isShowAdd) {
          return '添加菜单';
        } else {
          return '编辑菜单';
        }
      },
    },
    // 目录类型不显示
    directoryTypeNotDisplayed: {
      get() {
        if (this.form.menuType === this.menuType.MENU_TYPE_CATALOGUE) {
          return false;
        } else {
          return true;
        }
      },
    },
    // 外部链接不显示
    linkNotDisplayed: {
      get() {
        if (this.form.menuType === this.menuType.MENU_TYPE_MENU && this.form.isLink) {
          return false;
        } else {
          return true;
        }
      },
    },
    // 路由label名称
    // routeLabName: {
    //   get() {
    //     if (this.form.isLink === 1 && this.form.menuType === this.menuType.MENU_TYPE_MENU) {
    //       return '链接：';
    //     } else {
    //       return '路由：';
    //     }
    //   },
    // },
    // 上级菜单label名称
    // parentMenuLabName: {
    //   get() {
    //     if (this.form.menuType === this.menuType.MENU_TYPE_BUTTON) {
    //       return '所属菜单：';
    //     } else {
    //       return '上级菜单：';
    //     }
    //   },
    // },
  },
  data() {
    return {
      menuTypeOptions,
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      // 菜单类型
      menuType: MenuType,
      //权限菜单
      menuList: [],
      form: { ...initForm },
      //是否展示选择
      showOption: [
        {
          id: '0',
          value: '是',
        },
        {
          id: '1',
          value: '否',
        },
      ],
      rules: {
        platformId: [{ required: true, message: '请选择系统', trigger: 'change|blur' }],
        menuType: [{ required: true, message: '请选择菜单类型', trigger: 'blur' }],
        menuName: [{ required: true, message: '请选择输入菜单名称', trigger: 'blur' }],
        orderNum: [{ required: true, message: '请输入菜单排序', trigger: 'blur' }],
        menuPath: [{ required: true, message: '请输入菜单路由', trigger: 'blur' }],
        component: [{ required: true, message: '请输入菜单组件', trigger: 'blur' }],
        authority: [{ required: true, message: '请输入权限标识', trigger: 'blur' }],
      },
    };
  },
  methods: {
    //保存
    save: debounce(function () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isShowEdit) {
            this.editMenu();
          } else {
            this.addMenu();
          }
        }
      });
    }, 500),
    //取消
    cancel() {
      if (this.isShowEdit) {
        this.$emit('update:isShowEdit', false);
      } else {
        this.$emit('update:isShowAdd', false);
        this.resetData();
      }
    },
    //重置数据
    resetData() {
      this.form = {
        ...initForm,
      };
      this.$refs[`ruleForm`].resetFields();
    },
    //选择平台名称
    selectPlatform(id) {
      const selectedPlatform = this.options.filter((item) => {
        if (item.platformId === id) return item;
      })[0];
      this.form.platformName = selectedPlatform.platformName;
      this.form.moduleName = selectedPlatform.moduleName;
      this.getMenuList();
    },

    /**网络请求 */
    //添加菜单
    async addMenu() {
      const result = await api.addMenu(this.submitMenuDataTidy());
        if (result.code === this.$CODE) {
          this.$message.success('添加成功');
          this.$emit('update:isShowAdd', false);
          this.$emit('uploadData');
          this.resetData();
        } else {
          this.$message.error(result.msg);
        }
    },
    //编辑菜单
    async editMenu() {
      try {
        const result = await api.editMenu(this.submitMenuDataTidy());
        if (result.code === this.$CODE) {
          this.$message.success('修改成功');
          this.$emit('update:isShowEdit', false);
          this.$emit('uploadData');
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error('修改失败');
      }
    },

    //获取菜单列表
    async getMenuList() {
      try {
        const result = await api.getMenuList(this.form.platformId, 1, 9999);
        if (result.code === this.$CODE) {
          this.menuList = result.data.menuList;
        }
      } catch (error) {}
    },

    /**数据处理 */
    // 提交菜单数据处理
    submitMenuDataTidy() {
      const form = { ...this.form };
      if (!form.parentMenuId) {
        form.parentMenuId = '0';
      }
      form.visible = form.visible === '1' ? true : false;
      form.isExternalLink = form.isLink === 1 ? true : false;
      return form;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
</style>
